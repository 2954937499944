import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Typography, Container } from '@material-ui/core'

const useStyles = makeStyles({
})

export default () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Box
      bgcolor="primary.main"
      fontFamily="Bungee"
      fontSize={36}
      paddingY={0.5}
      marginBottom={2}
      color="white"
    >
      <Container maxWidth="md">
        Osakevinkki
      </Container>
    </Box>
  )
}
