import { createMuiTheme } from '@material-ui/core/styles'
import primary from '@material-ui/core/colors/blueGrey'
import secondary from '@material-ui/core/colors/orange'

export default createMuiTheme({
  palette: {
    primary,
    secondary,
    background: '#f9f5e5',
  },
  typography: {
    fontFamily: 'Montserrat',
    chartLegend: {
      fontSize: 10,
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 14,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '4px!important',
      },
    },
    MuiPickersDateRangePickerInput: {
      toLabelDelimiter: {
        margin: '8px 8px!important',
      },
    },
  },
});