import React from 'react';
import { useTheme } from '@material-ui/core/styles'
import { Paper, Box, Typography } from '@material-ui/core'

export default () => {
  const theme = useTheme()

  return (
    <Box marginBottom={3}>
      <Box display="flex" mb={2} display="flex" alignItems="center">
        <Box mr={2}>
          <Paper>
            <Box p={1} bgcolor="secondary.100" borderRadius="borderRadius">
              <img
                component="img"
                src="/apple-icon-144x144.png"
                height={144/4}
              />
            </Box>
          </Paper>
        </Box>
        <Typography variant="h5">
          Suomen johtava sijoitus&shy;neuvonta&shy;palvelu
        </Typography>
      </Box>
      <Box component={Typography} pb={1}>
        Osakevinkin mullistava retrospektiivinen algoritmi kertoo sinulle erehtymättömästi oikeat sijoituspäätökset ja niiden täsmälliset ajankohdat. Algoritmi toimii yhtä tarkasti ja luotettavasti niin tämän päivän luvuilla kuin datalla kauempaakin historian syövereistä.
      </Box>
      <Typography>
        Sijoituspäätöstesi validointi ei ole koskaan ollut näin helppoa!
      </Typography>
    </Box>
  )
}
