import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery, Box, CircularProgress, Typography } from '@material-ui/core'
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts'
import { format } from 'date-fns'
import { range, path } from 'rambda'

export default ({ data, limits }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const isMobile = !useMediaQuery('(min-width:600px')
  const lineWidth = isMobile ? 1 : 2
  const fontSize = isMobile ? 10 : 14
  const theme = useTheme()

  useEffect(() => {
    //window.addEventListener('resize', () => setWindowHeight(window.innerHeight))
    setWindowHeight(window.innerHeight)
  }, [])

  const renderDot = ({ cx, cy, payload }) => {
    const RADIUS = 10
    const STROKE = 3

    const isMin = payload.time === limits.min.time
    const isMax = payload.time === limits.max.time

    if (isMin || isMax) return (
      <svg x={cx - RADIUS - STROKE} y={cy - RADIUS - STROKE} key={cx}>
        <circle
          cx={RADIUS + STROKE}
          cy={RADIUS + STROKE}
          r={RADIUS}
          strokeWidth={STROKE}
          stroke={isMin ? theme.palette.secondary.main : theme.palette.primary.main}
          fill={(isMin ? theme.palette.secondary.main : theme.palette.primary.main) + '88'}
        />
      </svg>
    )
  }

  const renderLegend = () => {
    const RADIUS = 5
    const STROKE = 2

    const renderIcon = color => (
      <svg width={(RADIUS + STROKE * 2) * 2} height={(RADIUS + STROKE) * 2}>
        <circle
          cx={RADIUS + STROKE}
          cy={RADIUS + STROKE}
          r={RADIUS}
          strokeWidth={STROKE}
          stroke={color}
          fill={color + '88'}
        />
      </svg>
    )

    const sellTime = `Sinun olisi pitänyt myydä ${data.type === 'today' ? 'klo' : ''} ${limits.max.formattedTime}`
    const buyTime = `Sinun olisi pitänyt ostaa ${data.type === 'today' ? 'klo': ''} ${limits.min.formattedTime}`

    return (
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Box mb={1}>
          <Box display="flex" alignItems="center">
            {renderIcon(theme.palette.primary.main)}
            <Typography variant="caption" color="primary"><b>{sellTime}</b></Typography>
          </Box>
          <Box display="flex" alignItems="center">
            {renderIcon(theme.palette.secondary.main)}
            <Typography variant="caption" color="primary"><b>{buyTime}</b></Typography>
          </Box>
        </Box>
        <Typography variant="caption" color="primary" align="center">
          { limits.max.time < limits.min.time ?
              `10 000 € sijoituksella menetit ${Math.round(10000*((limits.max.value - limits.min.value) / limits.max.value))} € jos et myynyt`
            :
              `10 000 € sijoituksella olisit tienannut ${Math.round(10000*((limits.max.value - limits.min.value) / limits.min.value))} €`
          }
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      height={windowHeight / 2}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      { !data ?
        <CircularProgress size={64} />
      :
        data.values && data.values.length ?
          <ResponsiveContainer width="100%" heith="100%">
            <LineChart
              data={data.values}
              margin={{ right: 13 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                axisLine={{ strokeWidth: lineWidth }}
                ticks={data.type === 'today' ? range(0, 9).map(index => path('values.0.time', data) - 60 * 1000 + index * 60 * 60 * 1000) : null }
                tickFormatter={time => time && format(time, data.type === 'today' ? 'HH:mm' : 'dd.MM.')}
                tick={{ fontSize }}
                domain={['dataMin', 'dataMax']}
                type="number"
                tickCount={data.type === 'today' ? null : 10}
              />
              <YAxis
                dataKey="value"
                axisLine={{ strokeWidth: lineWidth }}
                domain={[
                  (limits.min.value - (limits.max.value - limits.min.value) * 0.1),
                  (limits.max.value + (limits.max.value - limits.min.value) * 0.1),
                ]}
                ticks={range(0, 10).map(index => parseFloat((limits.min.value + index * (limits.max.value - limits.min.value) / 9).toPrecision(3)))}
                width={isMobile ? 35 : 50}
                tick={{ fontSize }}
                tickCount={20}
              />
              <Line
                animationDuration={100}
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                strokeWidth={lineWidth}
                dot={renderDot}
              />
              <Tooltip
              labelFormatter={label => format(label, data.type === 'today' ? 'HH:mm' : 'dd.MM.')}
                formatter={value => [value, null]}
                contentStyle={{
                  textAlign: 'center',
                  padding: theme.spacing(1),
                  borderRadius: 4,
                  borderWidth: 2,
                  borderColor: theme.palette.primary.main,
                  padding: 0,
                }}
                labelStyle={{
                  color: 'white',
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.primary.main,
                  width: 80,
                  paddingBottom: 2,
                }}
              />
              <Legend verticalAlign="top" content={renderLegend} />
            </LineChart>
          </ResponsiveContainer>
        :
          <Box m={2}>
            <Typography variant="h6" color="primary" align="center">Pörssi on tänään kiinni, valitse jokin ajankohta menneisyydestä</Typography>
          </Box>
      }
    </Box>
  )
}
