import React, { useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, useMediaQuery  } from '@material-ui/core'
import { LocalizationProvider, DateRangePicker } from '@material-ui/pickers'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import fi from 'date-fns/locale/fi'
import { format, sub } from 'date-fns'

export default ({ type, dates, onChange }) => {
  const [tmpDates, setTmpDates] = useState([sub(new Date(), { days: 30 }), new Date()])

  const isMobile = !useMediaQuery('(min-width:600px')

  const handleRadioChange = ({ target: { value }}) => {
    onChange({ type: value, dates })
  }

  const handleDatesChange = newDates => {
    setTmpDates(newDates)
    onChange({ type: 'history', dates: newDates })
  }

  return (
    <RadioGroup name="time" value={type} onChange={handleRadioChange} row>
      <Box mt={isMobile ? 0 : 1} mb={1} mr={1}>
        <FormControlLabel value="today" control={<Radio />} label={`Tänään (${format(new Date(), 'dd.MM.yyyy')})`} />
      </Box>
      <Box display="flex" alignItems="center" style={{ marginLeft: -11 }}>
        <Radio value="history" />
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={fi}>
          <DateRangePicker
            key={dates}
            onChange={setTmpDates}
            disableFuture
            value={tmpDates}
            onAccept={handleDatesChange}
            startText="Alku"
            endText="Loppu"
            toText=""
            cancelLabel="Peruuta"
          />
        </LocalizationProvider>
      </Box>
    </RadioGroup>
  )
}
