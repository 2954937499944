import React, { useState } from 'react';
import { Box, Paper, TextField  } from '@material-ui/core'
import { Autocomplete  } from '@material-ui/lab'
import { concat } from 'rambda'

export default ({ instruments, instrument, onChange }) => {
  return (
    <Autocomplete
      options={instruments ? concat(instruments.indexes)(instruments.shares) : []}
      value={instrument}
      onChange={(event, value) => value && onChange(value)}
      getOptionLabel={option => option.name}
      renderInput={props => <TextField {...props} label="Instrumentti" variant="outlined" />}
      style={{ width: '100%' }}
    />
  )
}
