import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { Box, Container, CssBaseline, Icon, Paper, Typography, Button } from '@material-ui/core'
import { format, sub } from 'date-fns'

import Chart from './Chart'
import Header from './Header'
import Hero from './Hero'
import DateSelector from './DateSelector'
import theme from './theme'
import InstrumentSelector from './InstrumentSelector'

export default () => {
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [limits, setLimits] = useState(null)
  const [type, setType] = useState('today')
  const [dates, setDates] = useState([sub(new Date(), { days: 30 }), new Date()])
  const [instruments, setInstruments] = useState(null)
  const [instrument, setInstrument] = useState(null)

  useEffect(() => {
    getInstruments()
  }, [])

  const getInstruments = () => {
    const url = `https://r6mdrj8p4m.execute-api.eu-central-1.amazonaws.com/dev/instruments`
    fetch(url).then(result => result.json()).then(data => {
      setInstruments(data)
      setInstrument(data.indexes[0])
      getData({ type: 'today', instrument: data.indexes[0] })
    })
  }

  const getData = ({ type, dates, instrument }) => {
    setData(null)
    setError(null)
    const url = type === 'today' ?
      `https://r6mdrj8p4m.execute-api.eu-central-1.amazonaws.com/dev/data/today?instrument=${instrument.id}`
    :
      `https://r6mdrj8p4m.execute-api.eu-central-1.amazonaws.com/dev/data/history?from=${format(dates[0], 'yyyy-MM-dd')}&to=${format(dates[1], 'yyyy-MM-dd')}&instrument=${instrument.id}`
    fetch(url).then(result => result.json()).then(result => {
      let min = { value: Infinity }, max = { value: -Infinity }
      const newData = result && result.chartData && ({
        type: result.type,
        values: result.chartData.cp.map(item => {
                  const [time, value] = item
                  const formattedTime = format(new Date(time), type === 'today' ? 'HH:mm' : 'dd.MM.')
                  value === Math.min(min.value, value) && (min = { time, formattedTime, value })
                  value === Math.max(max.value, value) && (max = { time, formattedTime, value })
                  return {
                    time,
                    formattedTime,
                    value,
                  }
                }),      })
      setLimits({ min, max })
      setData(newData)
    }).catch(setError)
  }

  const handleInstrumentChange = instrument => {
    setInstrument(instrument)
    getData({ type, dates, instrument })
  }

  const handleDateChange = ({ type, dates }) => {
    setType(type)
    setDates(dates)
    getData({ type, dates, instrument })
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box bgcolor="secondary.50" minHeight="100vh" paddingBottom={2}>
        <Header />
        <Container maxWidth="md">
          <Hero />
          <Box component={Paper} p={2} mb={2}>
            <DateSelector type={type} dates={dates} onChange={handleDateChange} />
            <Box mt={2} mb={3} borderTop={1} borderColor="grey.300" />
            <InstrumentSelector instruments={instruments} instrument={instrument} onChange={handleInstrumentChange} />
          </Box>
          <Box component={Paper} p={2}>
            { error ?
              <Box p={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box component={Typography} pb={3}>Virhe datan latauksessa</Box>
                <Button
                  startIcon={<Icon>refresh</Icon>}
                  color="primary"
                  variant="contained"
                  onClick={() => getData({ type, dates, instrument })}
                >
                  Yritä uudelleen
                </Button>
              </Box>
            :
              <Chart data={data} limits={limits} />
            }
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
